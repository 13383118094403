<template>
  <div class="nex-home">
    <NexSidebar :show="testShow" />
    <div class="nex-main">
      <NexHeader @btnclick="sideBarShow" />
      <NexView />
    </div>
  </div>
</template>
<script>

import NexHeader from "@/view/home/header/NexHeader"
import NexView from "@/view/home/view/NexView"
import NexSidebar from "@/view/home/sidebar/NexSidebar"
export default {
  name: 'NexHome',
  components: {
    NexSidebar,
    NexView,
    NexHeader
  },
  props: {

  },
  data() {
    return {
      testShow: true
    }
  },
  computed: {
  },
  watch: {

  },
  mounted() {
  },
  methods: {
    sideBarShow(val) {
      console.log(val);
      this.testShow = val;
    }
  }
};
</script>
<style lang='less' scoped>
@media screen and (min-width: 960px) {}

.nex-home {
  display: flex;

  .nex-main {
    flex: 1;
    width: 100%;
  }
}
</style>