<template>
   <div class="nex-view">
      <div v-for="m in menu" :key="m.id">
         <!-- 大分类 -->
         <p class="type1">{{ m.title }}</p>
         <div v-for="i in m.children" :key="i.id">
            <!-- 小分类 -->
            <p class="type2" :id="i.index">{{ i.title }}</p>
            <el-row>
               <el-col style="padding:.2rem .3rem;" :xs="24" :sm="8" :md="6" v-for="d in data" :key="d.type + d.id + ''" v-show="i.id == d.type">
                  <el-tooltip  class="item" effect="dark" placement="bottom">
                     <template slot="content">
                        <p style="max-width:200px;font-size: .24rem;text-align: left;line-height: .3rem;color: #eeeeee;">{{d.intro}}</p>
                     </template>
                     <NexCard bgcolor="#ffffff" @click.native="toUrl(d.url)">
                        <div>
                           <img :src="d.logo" alt="">
                        </div>
                        <div>
                           <span class="title">{{d.title}}</span>
                           <p>{{ d.intro }}</p>
                           <div class="wzzd" @click.stop="toUrl(d.url)">
                              <el-tooltip class="item" effect="dark" content="直达" placement="right">
                                 <span class="iconfont">&#xe61d;</span>
                              </el-tooltip>
                           </div>
                        </div>
                     </NexCard>
                  </el-tooltip>
               </el-col>
            </el-row>
         </div>
      </div>
   </div>
</template>
<script>
import NexCard from "@/view/home/view/card/NexCard"
import {soft} from "@/network/soft"
import {menu} from "@/network/menu"
import {website} from "@/network/website"
export default {
   name: 'NexView',
   components: {
      NexCard
   },
   props: {

   },
   data() {
      return {
         menu: [
         ],
         data: [
         ]
      }
   },
   computed: {

   },
   watch: {

   },
   mounted() {
      // 是请求所有网站资源和软件资源
      let m = menu();
      let s = soft();
      let w = website();

      m.then(val =>{
         let data = val.data
         if (val.data.code == 0){
            let d = data.data;
            for (let item of d){
               this.menu.push(item)
            }
         }
      })
      s.then(val => {
         let data = val.data
         if (val.data.code == 0){
            let d = data.data;
            for (let item of d){
               this.data.push(item)
            }
         }
      })
      w.then(val => {
         let data = val.data
         if (val.data.code == 0){
            let d = data.data;
            for (let item of d){
               this.data.push(item)
            }
         }
      })

   },
   methods: {
      tonEX(selecter) {
         this.$el.querySelector(selecter).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
         })
      },
      toUrl(url){
         window.open(url);
      },
      toIntro(){
         console.log("执行了into")
      }
   }
};
</script>
<style lang='less' scoped>
// .el-col{
//    padding: .2rem;
// }

.type1 {
   font-weight: 700;
   font-size: .34rem;
}

.type2 {
   font-size: .3rem;
   color: #898a8b;
   padding: .2rem .4rem;
}

.nex-view {
   width: 100%;
   height: calc(100vh - 1.2rem);
   padding: .3rem;
   overflow: scroll;
   box-sizing: border-box;
   background-color: #f9f9f9;
}

// 隐藏滚动条
.nex-view::-webkit-scrollbar {
   display: none;
}



@media screen and (min-width: 960px) {
   .nex-view {
      height: calc(100vh - 75px);
      overflow: scroll;
   }

}

.nex-card {
   display: flex;

   & :nth-child(1) {
      position: relative;
      height: 1.4rem;
      width: 1.4rem;

      img {
         position: absolute;
         left: 50%;
         top: 50%;
         width: 60%;
         height: 60%;
         border-radius: 50%;
         border: .1px solid #d8d8d8;
         transform: translate(-50%, -50%);
      }
   }

   & :nth-child(2) {

      width: 30px;
      position: relative;
      flex: 1;
      padding: .25rem;
      padding-left: 0;

      .title {
         color: #282a2d;
         font-weight: 700;
         font-size: .3rem;

         &:hover {
            color: #9c9204;
         }
      }

      p {
         width: 100%;
         color: #6c757d;
         font-size: .22rem;
         padding: 0;
         padding-top: .17rem;
         overflow: hidden; //不允许内容超出盒子
         white-space: nowrap; //不允许文本跨行
         text-overflow: ellipsis; //文本超出部分以“...”省略号表示
      }

      & :nth-child(3) {
         position: absolute;
         right: 1%;
         top: 50%;
         transform: translate(-50%, -50%);

      }

   }

}

.wzzd {
   span {
      color: #b8bbbd;

      &:hover {
         color: #406585;
         cursor: pointer;
      }
   }
}

</style>