<template>
    <div class="nex-card" :style="{ backgroundColor:bgcolor}">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'NexCard',
    components: {

    },
    mixins: [],
    props: {
        height:{
            default:'1.5rem',
            type:String
        },
        bgcolor:{
            default:'transparent',
            type:String
        }
    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {

    }
};
</script>
<style lang='less' scoped>
.nex-card{
    height: 100%;
    width: 100%;
    border-radius: .15rem;
    transition-property: all;
    transition-duration: .3s;
    border: 1px solid #aca8a82d;
    box-sizing: border-box;
    cursor: pointer;
    &:hover{
        transform: translate(0,-.1rem);
        box-shadow: 0rem .1rem .2rem 0rem #5553532d;
        border: 1px solid #e9a6a6;
    }
}

</style>