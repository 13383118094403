import axios from "axios";

export function request(config) {
    const instance = axios.create({
        baseURL: "http://47.116.214.197:8100",
        timeout: 2000,
    })

    // instance.interceptors.request.use(res => {
    //     const token = localStorage.getItem('token');
    //     res.headers.Authorization = `Bearer ${token}`;
    //     return res;
    //     // headers: {Authorization: 'Bearer ${token}'}
    // })

    // instance.interceptors.response.use( response => {
    //     if (response.data.code == 401){
    //         router.push('/login')
    //     }
    //     return response
    // }, error => {
    //     if (error.response.status !== 200){
    //         router.push('/login')
    //     }
    // })

    return instance(config);
}