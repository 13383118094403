import {request} from "@/network/request";


export function website() {
    return request({
        url:'/api/v1/website',
        method: 'get'
    })
}

