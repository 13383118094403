import {request} from "@/network/request";


export function menu() {
    return request({
        url:'/api/v1/menu',
        method: 'get'
    })
}

