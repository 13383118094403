<template>
   <div class="nex-header">
      <!-- pc端 -->
     <div class="md hidden-sm-and-down">
         <el-row type="flex" style="height: 100%;" align="middle" justify="left">

            <el-col :span="1">
               <span v-if="btnDis" @click="siclick" class="iconfont" style="font-size: 20px;margin-left: -20px;cursor: pointer;">&#xe89a;</span>
               <span v-else @click="siclick" class="iconfont" style="font-size: 20px;margin-left: -20px;cursor: pointer;">&#xe897;</span>
            </el-col>
            <el-col :span="1">
               <a href="#">博客</a>
            </el-col>
            <el-col :span="1">
               <a href="#"></a>
            </el-col>
         </el-row>
     </div>
     <!-- 移动端 -->
     <div class="sm xs hidden-md-and-up">
         <el-row type="flex" align="middle" justify="left">
            <el-col :span="8">
               <div style="width: 100%;height: 100%;padding-left: .2rem;" @click="siclick">
                  <span class="iconfont" style="font-size: .4rem;">&#xe89a;</span>
               </div>
            </el-col>
            <el-col :span="8" style="text-align: center;">
               <h1 style="font-size: .5rem;">NEXERK</h1>
            </el-col>
            <el-col :span="8">
            </el-col>
         </el-row>
     </div>
   </div>
</template>
<script>
export default {
   name: 'NexHeader',
   components: {
     
   },
   mixins: [],
   props: {
     
   },
   data() {
     return {
       btnDis:true
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   mounted() {
     
   },
   methods: {
      siclick(){
         console.log("点击了")
         this.btnDis = !this.btnDis;
         this.$emit('btnclick', this.btnDis)
      }
   }
};
</script>
<style lang='less' scoped>
@import '@/assets/css/base.less';

.nex-header{
   width: 100%;
   @media screen and (min-width: 960px) {
      .md{
         height: 75px;
         background-color: @default-header-color;
         text-align: center;
      }
   }

   .xs{
      height: 1.2rem;
      background-color: @default-header-color;
      .el-row{
         height: 100%;
      }
   }
}
</style>