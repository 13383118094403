<template>
  <div id="app">
    <NexHome />
  </div>
</template>

<script>
import NexHome from "@/view/home/NexHome"
export default {
  name: 'App',
  components: {
    NexHome
  }
}
</script>

<style lang="less">
@import '@/assets/css/base.less';
</style>
