<template>
  <div class="nex-sidebar">
    <el-drawer class="hidden-md-and-up" size="60%" :visible="xsShow" :show-close="false" direction="ltr"
      :withHeader="false" @close="closeXsSidebar">
      <div class="xs">
        <div class="logo"></div>
        <!-- 手机端菜单栏 -->
        <el-menu default-active="2" class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff"
          active-text-color="#ffd04b">
          <el-submenu v-for="item in menu" :key="item.id" :index="`#${item.index}`">
            <template slot="title">
              <div>
                <img :src="item.logo" style="width: .3rem;height: .3rem;margin-right: .3rem;">
                <span>{{ item.title }}</span>
              </div>
            </template>
            <el-menu-item @click="itemClick(item2.index);closeXsSidebar()" v-for="item2 in item.children" :key="item2.id"
              :index="`#${item2.index}`">{{ item2.title }}</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </el-drawer>

    <div class="md hidden-sm-and-down" :class="mdShow">
      <div class="logo">
      </div>
      <!-- pc端菜单栏 -->
      <el-menu default-active="2" class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff"
        active-text-color="#ffd04b">
        <el-submenu v-for="item in menu" :key="item.id" :index="`#${item.index}`">

          <template slot="title">
            <div @click="itemClick(item.index)">
              <img :src="item.logo" style="width: .3rem;height: .3rem;margin-right: .3rem;">
              <span>{{ item.title }}</span>
            </div>
          </template>
          <el-menu-item @click="itemClick(item2.index)" v-for="item2 in item.children" :key="item2.id"
            :index="`#${item2.index}`">{{ item2.title }}</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { menu } from "@/network/menu"
export default {
  name: 'NexSidebar',
  components: {

  },
  props: {
    show: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      xsShow: false,
      menu: []
    }
  },
  computed: {
    mdShow() {
      return {
        'mdShow': !this.show
      }
    }
  },
  watch: {
    show(n) {
      let w = document.body.clientWidth;
      if (w < 992) {
        if (n) {
          this.xsShow = true
        } else {
          this.xsShow = true
        }
      }
    }
  },
  mounted() {
    // 请求菜单列表
    let m = menu();
    m.then(val => {
      let data = val.data
      if (val.data.code == 0) {
        // 处理数据，将获取到的数据格式处理成想要的数据格式
        let d = data.data;
        for (let item of d) {
          this.menu.push(item);
        }
      } else {
        console.log("数据请求失败")
      }
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    closeXsSidebar() {
      this.xsShow = false;
    },
    itemClick(val) {
      window.location.replace(`#${val}`)
      this.$emit('clickopt', val);
    }
  }
};
</script>

<style lang='less' scoped>
@import '@/assets/css/base.less';

.nex-sidebar{
  user-select:none;
}

.xs {
  width: 100%;
  height: 100vh;
  background-color: @default-sidebar-color;

  .logo {
    height: 1.8rem;
    background-color: @default-sidebar-color;
  }

  .el-menu {
    height: calc(100% - 1.8rem);
    background-color: #a09f9f;
    border: 0;
  }
}

.md {
  width: 200px;
  height: 100vh;
  background-color: @default-sidebar-color;
  transition-property: margin;
  transition-duration: .4s;

  .logo {
    height: 75px;
    background-color: @default-sidebar-color;
  }

  .el-menu {
    height: calc(100% - 75px);
    background-color: @default-sidebar-color;
    border: 0;
  }


}

.mdShow {
  margin-left: -200px;
}
</style>