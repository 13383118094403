import {request} from "@/network/request";


export function soft() {
    return request({
        url:'/api/v1/soft',
        method: 'get'
    })
}

